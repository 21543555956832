<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title font-weight-bolder text-dark">
        Справка за зареждания по подизпълнител и/или машина
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <b-row>
        <b-col class="pb-0" :md="5">
          <b-form-group
            id="subcontractors-group"
            label-for="subcontractors-input"
          >
            <FormSelect
              id="subcontractors-input"
              :placeholder="$t('FORM_LABELS.COMPANY')"
              type="select"
              name="subcontractors"
              :options="subcontractors"
              clearable="true"
              v-model="params.subcontractorId"
              @input="getMachines"
            ></FormSelect>
          </b-form-group>
        </b-col>
        <b-col class="pb-0" :md="5">
          <b-form-group
            id="subcontractors-group"
            label-for="subcontractors-input"
          >
            <FormSelect
              id="rentedMachines-input"
              :placeholder="$t('MENU.ITEM.ASSETS.RENTED_MACHINE')"
              type="select"
              name="rentedMachines"
              :options="rentedMachines"
              clearable="true"
              v-model="params.rentedMachineId"
            ></FormSelect>
          </b-form-group>
        </b-col>
        <b-col class="pb-0" :md="5">
          <b-form-group
            id="fuelBySubcdateFrom-group"
            label-for="fuelBySubcdateFrom-input"
          >
            <FormDatepicker
              id="fuelBySubcdateFrom-input"
              type="text"
              name="fuelBySubcdateFrom"
              :i18n="{ placeholder: 'FORM_LABELS.DATE_FROM' }"
              :validateState="{}"
              v-model="params.dateFrom"
            ></FormDatepicker>
          </b-form-group>
        </b-col>
        <b-col class="pb-0" :md="5">
          <b-form-group
            id="fuelBySubcdateTo-group"
            label-for="fuelBySubcdateTo-input"
          >
            <FormDatepicker
              id="fuelBySubcdateTo-input"
              type="text"
              name="fuelBySubcdateTo"
              :i18n="{ placeholder: 'FORM_LABELS.DATE_TO' }"
              :validateState="{}"
              v-model="params.dateTo"
            ></FormDatepicker>
          </b-form-group>
        </b-col>
        <b-col class="d-flex align-center justify-center" :md="2">
          <button class="btn btn-success" v-on:click="handleClick">
            Генерирай PDF
          </button>
        </b-col>
      </b-row>
      <div
        v-show="_.size(errors) > 0"
        role="alert"
        class="mt-5 alert alert-danger"
      >
        <div class="alert-text" v-for="(error, i) in errors" :key="i">
          {{ error[0] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";

import { FETCH_SUBCONTRACTORS } from "@/modules/subcontractors/store/subcontractor.module";
import { FETCH_RENTED_MACHINES } from "@/modules/rented-machines/store/rentedmachine.module";
import { GENERATE_FUEL_BY_SUBCONTRACTOR_PDF_URL } from "../store/statistics.module.js";

export default {
  name: "FuelChargesBySubcStats",
  components: {
    FormSelect,
    FormDatepicker,
  },
  data() {
    return {
      errors: [],
      subcontractors: [],
      rentedMachines: [],
      params: {
        subcontractorId: null,
        rentedMachineId: null,
        dateFrom: null,
        dateTo: null,
      },
    };
  },
  mounted() {
    this.$store
      .dispatch(
        FETCH_SUBCONTRACTORS,
        this.$url.transformParams({
          onlySelectValues: true,
          selectValueKey: "company_name",
          subcontractorTypeId: 1,
        })
      )
      .then((data) => {
        this.subcontractors = data.data.items;
      });
    this.getMachines();
  },
  methods: {
    getMachines(id = null) {
      this.params.rentedMachineId = null;
      this.$store
        .dispatch(
          FETCH_RENTED_MACHINES,
          this.$url.transformParams({
            subcontractorId: id,
          })
        )
        .then((data) => {
          this.rentedMachines = data.data.items.map((m) => ({
            id: m.id,
            name: m.name + " - " + m.subcontractorName,
          }));
        });
    },
    handleClick() {
      this.$store
        .dispatch(
          GENERATE_FUEL_BY_SUBCONTRACTOR_PDF_URL,
          this.$url.transformParams(this.params)
        )
        .catch((response) => {
          this.errors = response.data.errors;
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message,
          });
        });
    },
  },
};
</script>

<style></style>
