<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title font-weight-bolder text-dark">
        Справка по товарителници
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <b-row>
        <b-col class="pb-0" :md="5">
          <b-form-group id="assignors-group" label-for="assignors-input">
            <FormSelect
              id="assignors-input"
              :placeholder="$t('FORM_LABELS.ASSIGNOR')"
              type="select"
              name="assignors"
              :options="assignors"
              clearable="true"
              v-model="params.assignorId"
              @input="fetchAssignorLocations"
            ></FormSelect>
          </b-form-group>
        </b-col>
        <b-col class="pb-0" :md="5">
          <b-form-group id="locations-group" label-for="locations-input">
            <FormSelect
              id="locations-input"
              :placeholder="$t('FORM_LABELS.LOCATION')"
              type="select"
              name="locations"
              :options="locations"
              clearable="true"
              v-model="params.locationId"
            ></FormSelect>
          </b-form-group>
        </b-col>
        <b-col class="pb-0" :md="5">
          <b-form-group
            id="date-from-bol-work-group"
            label-for="date-from-bol-work-input"
          >
            <FormDatepicker
              id="date-from-bol-work-input"
              type="text"
              name="date_from_bol_work"
              :i18n="{ placeholder: 'FORM_LABELS.DATE_FROM' }"
              :validateState="{}"
              v-model="params.dateFrom"
            ></FormDatepicker>
          </b-form-group>
        </b-col>
        <b-col class="pb-0" :md="5">
          <b-form-group
            id="date-to-bol-work-group"
            label-for="date-to-bol-work-input"
          >
            <FormDatepicker
              id="date-to-bol-work-input"
              type="text"
              name="date_to_bol_work"
              :i18n="{ placeholder: 'FORM_LABELS.DATE_TO' }"
              :validateState="{}"
              v-model="params.dateTo"
            ></FormDatepicker>
          </b-form-group>
        </b-col>
        <b-col class="d-flex align-center justify-center" :md="2">
          <button class="btn btn-success" v-on:click="handleClick">
            Генерирай EXCEL
          </button>
        </b-col>
      </b-row>
      <div
        v-show="_.size(errors) > 0"
        role="alert"
        class="mt-5 alert alert-danger"
      >
        <div class="alert-text" v-for="(error, i) in errors" :key="i">
          {{ error[0] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";
import { GENERATE_BOL_WORK_EXCEL_URL } from "../store/statistics.module.js";
import { FETCH_ASSIGNORS } from "@/modules/assignors/store/assignors.module.js";
import { FETCH_ASSIGNOR_LOCATIONS } from "@/modules/assignorslocation/store/location.module";

export default {
  name: "BolWorkStats",
  components: {
    FormSelect,
    FormDatepicker
  },
  data() {
    return {
      errors: [],
      assignors: [],
      locations: [],
      params: {
        dateFrom: null,
        dateTo: null,
        assignorId: null,
        locationId: null
      }
    };
  },
  mounted() {
    this.$store
      .dispatch(
        FETCH_ASSIGNORS,
        this.$url.transformParams({
          onlySelectValues: true,
          selectValueKey: "company_name"
        })
      )
      .then(data => {
        this.assignors = data.data.items;
        if (this.params.assignorId) {
          this.fetchAssignorLocations(this.params.assignorId);
        }
      });
  },
  methods: {
    fetchAssignorLocations(id) {
      this.locations = [];
      this.params.locationId = null;
      this.$store
        .dispatch(
          FETCH_ASSIGNOR_LOCATIONS,
          this.$url.transformParams({
            assignorId: id
          })
        )
        .then(data => {
          this.locations = data.data.items || [];
        });
    },
    handleClick() {
      this.$store
        .dispatch(
          GENERATE_BOL_WORK_EXCEL_URL,
          this.$url.transformParams(this.params)
        )
        .catch(response => {
          this.errors = response.data.errors;
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message
          });
        });
    }
  }
};
</script>

<style></style>
