<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <BolWorkStats></BolWorkStats>
        <MonthByMachineStats class="mt-10"></MonthByMachineStats>
        <FuelChargesStats class="mt-10"></FuelChargesStats>
        <FuelChargesBySubcStats class="mt-10"></FuelChargesBySubcStats>
        <FuelChargesByOwnStats class="mt-10"></FuelChargesByOwnStats>
        <FuelChargesStaffStats class="mt-10"></FuelChargesStaffStats>
        <FuelChargesFullStats class="mt-10"></FuelChargesFullStats>
        <FuelChargesByDayStats class="mt-10"></FuelChargesByDayStats>
        <ExpensesByMachineStats class="mt-10"></ExpensesByMachineStats>
        <WarehouseItemStats class="mt-10"></WarehouseItemStats>
      </div>
    </div>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import BolWorkStats from "./BolWorkStats";
import MonthByMachineStats from "./MonthByMachineStats";
import FuelChargesStats from "./FuelChargesStats";
import FuelChargesBySubcStats from "./FuelChargesBySubcStats";
import FuelChargesByOwnStats from "./FuelChargesByOwnStats";
import FuelChargesStaffStats from "./FuelChargesStaffStats";
import FuelChargesFullStats from "./FuelChargesFullStats";
import FuelChargesByDayStats from "./FuelChargesByDayStats";
import ExpensesByMachineStats from "./ExpensesByMachineStats";
import WarehouseItemStats from "./WarehouseItemStats";
import { permissionMixin } from "@/core/mixins/permissionMixin.js";

export default {
  name: "Statistics",
  mixins: [permissionMixin],
  components: {
    BolWorkStats,
    MonthByMachineStats,
    FuelChargesStats,
    FuelChargesBySubcStats,
    FuelChargesByOwnStats,
    FuelChargesStaffStats,
    FuelChargesFullStats,
    FuelChargesByDayStats,
    ExpensesByMachineStats,
    WarehouseItemStats
  },
  data() {
    return {
      isPageLoading: false
    };
  },
  watch: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.REF.REFERENCES"),
        route: { name: "list-statistics" }
      },
      { title: this.$i18n.t("MENU.ITEM.ASSETS.LIST") }
    ]);
  }
};
</script>
