<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title font-weight-bolder text-dark">
        Справка за зареждания - обща справка
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <b-row>
        <b-col class="pb-0" :md="5">
          <b-form-group id="fullDateFrom-group" label-for="fullDateFrom-input">
            <FormDatepicker
              id="fullDateFrom-input"
              type="text"
              name="fullDateFrom"
              :i18n="{ placeholder: 'FORM_LABELS.DATE_FROM' }"
              :validateState="{}"
              v-model="params.dateFrom"
            ></FormDatepicker>
          </b-form-group>
        </b-col>
        <b-col class="pb-0" :md="5">
          <b-form-group id="fullDateTo-group" label-for="fullDateTo-input">
            <FormDatepicker
              id="fullDateTo-input"
              type="text"
              name="fullDateTo"
              :i18n="{ placeholder: 'FORM_LABELS.DATE_TO' }"
              :validateState="{}"
              v-model="params.dateTo"
            ></FormDatepicker>
          </b-form-group>
        </b-col>
        <b-col class="d-flex align-center justify-center" :md="2">
          <button class="btn btn-success" v-on:click="handleClick">
            Генерирай PDF
          </button>
        </b-col>
      </b-row>
      <div
        v-show="_.size(errors) > 0"
        role="alert"
        class="mt-5 alert alert-danger"
      >
        <div class="alert-text" v-for="(error, i) in errors" :key="i">
          {{ error[0] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";

import { GENERATE_FUEL_FULL_PDF_URL } from "../store/statistics.module.js";

export default {
  name: "FuelChargesFullStats",
  components: {
    FormDatepicker,
  },
  data() {
    return {
      errors: [],
      params: {
        dateFrom: null,
        dateTo: null,
      },
    };
  },
  mounted() {
    //
  },
  methods: {
    handleClick() {
      this.$store
        .dispatch(
          GENERATE_FUEL_FULL_PDF_URL,
          this.$url.transformParams(this.params)
        )
        .catch((response) => {
          this.errors = response.data.errors;
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message,
          });
        });
    },
  },
};
</script>

<style></style>
