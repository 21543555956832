<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title font-weight-bolder text-dark">
        Справка за зареждания от и към бидон/цистерна
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <b-row>
        <b-col class="pb-0" :md="4">
          <b-form-group
            id="gas-tank-station-group"
            label-for="gas-tank-station-input"
          >
            <FormSelect
              id="gasStations-input"
              :placeholder="$t('MENU.ITEM.TANKS.TANK')"
              type="select"
              name="gasStations"
              :options="gasStations"
              clearable="true"
              v-model="params.gasTankStationId"
            ></FormSelect>
          </b-form-group>
        </b-col>
        <b-col class="pb-0" :md="3">
          <b-form-group id="date-from-group" label-for="date-from-input">
            <FormDatepicker
              id="date-from-input"
              type="text"
              name="date_from"
              :i18n="{ placeholder: 'FORM_LABELS.DATE_FROM' }"
              :validateState="{}"
              v-model="params.dateFrom"
            ></FormDatepicker>
          </b-form-group>
        </b-col>
        <b-col class="pb-0" :md="3">
          <b-form-group id="date-to-group" label-for="date-to-input">
            <FormDatepicker
              id="date-to-input"
              type="text"
              name="date_to"
              :i18n="{ placeholder: 'FORM_LABELS.DATE_TO' }"
              :validateState="{}"
              v-model="params.dateTo"
            ></FormDatepicker>
          </b-form-group>
        </b-col>
        <b-col class="d-flex align-center justify-center" :md="2">
          <button class="btn btn-success" v-on:click="handleClick">
            Генерирай PDF
          </button>
        </b-col>
      </b-row>
      <div v-show="_.size(errors) > 0" role="alert" class="mt-5 alert alert-danger">
        <div class="alert-text" v-for="(error, i) in errors" :key="i">
          {{ error[0] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";

import { FETCH_TANKS } from "@/modules/tanks/store/tanks.module";
import { GENERATE_FUEL_AVAILABLITIY_PDF_URL } from "../store/statistics.module.js";

export default {
  name: "FuelChargesStats",
  components: {
    FormSelect,
    FormDatepicker,
  },
  data() {
    return {
      errors: [],
      gasStations: [],
      params: {
        gasTankStationId: null,
        dateFrom: null,
        dateTo: null,
      },
    };
  },
  mounted() {
    this.$store.dispatch(FETCH_TANKS).then((data) => {
      this.gasStations = data.data.items;
    });
  },
  methods: {
    handleClick() {
      this.$store
        .dispatch(
          GENERATE_FUEL_AVAILABLITIY_PDF_URL,
          this.$url.transformParams(this.params)
        )
        .catch((response) => {
          this.errors = response.data.errors;
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message,
          });
        });
    },
  },
};
</script>

<style></style>
