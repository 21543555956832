<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title font-weight-bolder text-dark">
        Справка за разходи по машини
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <b-row>
        <b-col class="pb-0" :md="2">
          <b-form-group id="date-from-group" label-for="date-from-input">
            <FormSelect
              id="item-item-month-input"
              type="select"
              :options="months"
              v-model="params.startMonth"
              class="col-sm-12"
            ></FormSelect>
          </b-form-group>
        </b-col>
        <b-col class="pb-0" :md="2">
          <b-form-group id="date-from-group" label-for="date-from-input">
            <FormSelect
              id="item-item-year-input"
              type="select"
              :options="years"
              v-model="params.startYear"
              class="col-sm-12"
            ></FormSelect>
          </b-form-group>
        </b-col>
        <b-col class="pb-0 d-flex justify-center align-center" :md="2">
          <b>до</b>
        </b-col>
        <b-col class="pb-0" :md="2">
          <b-form-group id="date-from-group" label-for="date-from-input">
            <FormSelect
              id="item-item-month-input"
              type="select"
              :options="months"
              v-model="params.endMonth"
              class="col-sm-12"
            ></FormSelect>
          </b-form-group>
        </b-col>
        <b-col class="pb-0" :md="2">
          <b-form-group id="date-from-group" label-for="date-from-input">
            <FormSelect
              id="item-item-year-input"
              type="select"
              :options="years"
              v-model="params.endYear"
              class="col-sm-12"
            ></FormSelect>
          </b-form-group>
        </b-col>
        <b-col class="d-flex align-center justify-center" :md="2">
          <button class="btn btn-success" v-on:click="handleClick">
            Генерирай PDF
          </button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import { GENERATE_EXPENSES_BY_MACHINE_PDF_URL } from "../store/statistics.module.js";

export default {
  name: "MonthByMachineStats",
  components: {
    FormSelect
  },
  data() {
    return {
      params: {
        startMonth: new Date().getMonth(),
        startYear: new Date().getFullYear(),
        endMonth: new Date().getMonth() + 1,
        endYear: new Date().getFullYear()
      }
    };
  },
  computed: {
    months() {
      return [
        {
          name: "Януари",
          id: 1
        },
        {
          name: "Февруари",
          id: 2
        },
        {
          name: "Март",
          id: 3
        },
        {
          name: "Април",
          id: 4
        },
        {
          name: "Май",
          id: 5
        },
        {
          name: "Юни",
          id: 6
        },
        {
          name: "Юли",
          id: 7
        },
        {
          name: "Август",
          id: 8
        },
        {
          name: "Септември",
          id: 9
        },
        {
          name: "Октомври",
          id: 10
        },
        {
          name: "Ноември",
          id: 11
        },
        {
          name: "Декември",
          id: 12
        }
      ];
    },
    years() {
      const firstYear = 2021;
      const thisYear = new Date().getFullYear();
      let years = [];
      for (let year = firstYear; year <= thisYear; year++) {
        years.push({
          name: year,
          id: year
        });
      }
      return years;
    }
  },
  methods: {
    handleClick() {
      this.$store.dispatch(
        GENERATE_EXPENSES_BY_MACHINE_PDF_URL,
        this.$url.transformParams(this.params)
      );
    }
  }
};
</script>

<style></style>
