<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title font-weight-bolder text-dark">
        Справка за наличност по артикули на склад
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <b-row>
        <b-col class="pb-0" :md="5">
          <b-form-group
            id="gas-tank-station-group"
            label-for="gas-tank-station-input"
          >
            <FormSelect
              id="serviceWarehouseItems-input"
              :placeholder="$t('FORM_LABELS.WAREHOUSE_ITEM')"
              type="select"
              name="serviceWarehouseItems"
              :options="serviceWarehouseItems"
              clearable="true"
              v-model="params.serviceWarehouseItemId"
            ></FormSelect>
          </b-form-group>
        </b-col>
        <b-col class="pb-0" :md="5">
          <b-form-group
            id="date-from-warehouse-item-group"
            label-for="date-from-warehouse-iteminput"
          >
            <FormDatepicker
              id="date-from-warehouse-iteminput"
              type="text"
              name="date_from_warehouse_item"
              :i18n="{ placeholder: 'FORM_LABELS.DATE_FROM' }"
              :validateState="{}"
              v-model="params.dateFrom"
            ></FormDatepicker>
          </b-form-group>
        </b-col>
        <b-col class="d-flex align-center justify-center" :md="2">
          <button class="btn btn-success" v-on:click="handleClick">
            Генерирай PDF
          </button>
        </b-col>
      </b-row>
      <div
        v-show="_.size(errors) > 0"
        role="alert"
        class="mt-5 alert alert-danger"
      >
        <div class="alert-text" v-for="(error, i) in errors" :key="i">
          {{ error[0] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";

import { GENERATE_WAREHOUSE_ITEM_AVAILABLITIY_PDF_URL } from "../store/statistics.module.js";
import { FETCH_SERVICE_WAREHOUSE_ITEMS } from "@/modules/service-warehouse-items/store/service-warehouse-items.module";

export default {
  components: {
    FormSelect,
    FormDatepicker
  },
  data() {
    return {
      errors: [],
      serviceWarehouseItems: [],
      params: {
        serviceWarehouseItemId: null,
        dateFrom: null
      }
    };
  },
  mounted() {
    this.$store.dispatch(FETCH_SERVICE_WAREHOUSE_ITEMS).then(data => {
      this.serviceWarehouseItems = data.data.items.map(i => {
        return {
          id: i.id,
          name: i.name + " | " + i.warehouse
        };
      });
    });
  },
  methods: {
    handleClick() {
      this.$store
        .dispatch(
          GENERATE_WAREHOUSE_ITEM_AVAILABLITIY_PDF_URL,
          this.$url.transformParams(this.params)
        )
        .catch(response => {
          this.errors = response.data.errors;
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message
          });
        });
    }
  }
};
</script>

<style></style>
