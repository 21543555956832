<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title font-weight-bolder text-dark">
        Справка за зареждания по собствени машини (машина е опционално)
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <b-row>
        <b-col class="pb-0" :md="4">
          <b-form-group
            id="subcontractors-group"
            label-for="subcontractors-input"
          >
            <FormSelect
              id="ownMachines-input"
              :placeholder="$t('MENU.ITEM.ASSETS.MACHINE')"
              type="select"
              name="ownMachines"
              :options="ownMachines"
              clearable="true"
              v-model="params.ownMachineId"
            ></FormSelect>
          </b-form-group>
        </b-col>
        <b-col class="pb-0" :md="3">
          <b-form-group
            id="fuelByOwndateFrom-group"
            label-for="fuelByOwndateFrom-input"
          >
            <FormDatepicker
              id="fuelByOwndateFrom-input"
              type="text"
              name="fuelByOwndateFrom"
              :i18n="{ placeholder: 'FORM_LABELS.DATE_FROM' }"
              :validateState="{}"
              v-model="params.dateFrom"
            ></FormDatepicker>
          </b-form-group>
        </b-col>
        <b-col class="pb-0" :md="3">
          <b-form-group
            id="fuelByOwndateTo-group"
            label-for="fuelByOwndateTo-input"
          >
            <FormDatepicker
              id="fuelByOwndateTo-input"
              type="text"
              name="fuelByOwndateTo"
              :i18n="{ placeholder: 'FORM_LABELS.DATE_TO' }"
              :validateState="{}"
              v-model="params.dateTo"
            ></FormDatepicker>
          </b-form-group>
        </b-col>
        <b-col class="d-flex align-center justify-center" :md="2">
          <button class="btn btn-success" v-on:click="handleClick">
            Генерирай PDF
          </button>
        </b-col>
      </b-row>
      <div
        v-show="_.size(errors) > 0"
        role="alert"
        class="mt-5 alert alert-danger"
      >
        <div class="alert-text" v-for="(error, i) in errors" :key="i">
          {{ error[0] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";

import { FETCH_MACHINES } from "@/modules/machines/store/machine.module";
import { GENERATE_FUEL_BY_OWN_PDF_URL } from "../store/statistics.module.js";

export default {
  name: "FuelChargesByOwnStats",
  components: {
    FormSelect,
    FormDatepicker,
  },
  data() {
    return {
      errors: [],
      subcontractors: [],
      ownMachines: [],
      params: {
        ownMachineId: null,
        dateFrom: null,
        dateTo: null,
      },
    };
  },
  mounted() {
    this.getMachines();
  },
  methods: {
    getMachines() {
      this.params.ownMachineId = null;
      this.$store.dispatch(FETCH_MACHINES).then((data) => {
        this.ownMachines = data.data.items.map((m) => ({
          id: m.id,
          name: m.name,
        }));
      });
    },
    handleClick() {
      this.$store
        .dispatch(
          GENERATE_FUEL_BY_OWN_PDF_URL,
          this.$url.transformParams(this.params)
        )
        .catch((response) => {
          this.errors = response.data.errors;
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message,
          });
        });
    },
  },
};
</script>

<style></style>
